const transportServicesTextContent = {
    itemsList: [
        'Transporte de cargas en Argentina y hacia Mercosur',
        'Transporte de cargas Generales',
        'Transporte de cargas Peligrosas',
        'Transportes de cargas Especiales',
        'Habilitación Renpre (Sedronar)',
        'Habilitación ATA (Agente Transporte Aduanero)',
        'Habilitación TRAM (Transporte Aduanero Monitoreado)',
        'Atención a usuarios de minas y producción.',
    ]
  };
  
export { transportServicesTextContent };