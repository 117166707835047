import React from 'react';
import { Box, Flex, Link } from "@chakra-ui/react";

const ScrollToSection = () => {
  const sections = [
    { id: 'about-us', label: 'Quienes somos' },
    { id: 'mission', label: 'Misión' },
    { id: 'services', label: 'Servicios' },
    { id: 'web-platform', label: 'Plataforma Web' },
    { id: 'port', label: 'Gestión portuaria' },
    { id: 'transport', label: 'Transporte' },
    { id: 'units', label: 'Unidades' },
    { id: 'transport-services', label: 'Servicios de Transporte' },
    { id: 'contact', label: 'Contacto' },
  ];

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    const offset = 80; // Adjust this value to the height of your sticky navbar
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  return (
    <Box className="scroll-to-section" bg="white" py={2} px={4} boxShadow="md" position="sticky" top={16} zIndex={10}>
      <Flex justify="space-around">
        {sections.map((section) => (
          <Link
            key={section.id}
            onClick={() => scrollTo(section.id)}
            cursor="pointer"
            fontWeight="bold"
            _hover={{ textDecoration: 'underline', color: 'teal.500' }}
          >
            {section.label}
          </Link>
        ))}
      </Flex>
    </Box>
  );
};

export default ScrollToSection;