import React from 'react';
import { Text } from '@chakra-ui/react'
import { Heading } from '@chakra-ui/react';
import {
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons'

const ImageText = ({ sectionId, title, text, itemsList, img, inverted = false, align }) => {
  return (
    <div className="image-text-section" id={sectionId}>
      <Heading size='xl' className={`image-text--heading ${align === 'left' ? 'align-left' : ''}${align === 'right' ? 'align-right' : ''}`}>{title}</Heading>
      <div className={`image-text--container${inverted ? ' container-inverted' : ''}`}>
        {img && <img src={img} className="image-text--image" alt="description" />}
        <div className='image-text--content'>
          <Text fontSize='md' color="gray.600">{text}</Text>
          {itemsList && <List spacing={2} className="image-text--bullets-list">
            {itemsList?.map((item, index) => (
              <ListItem color="gray.600" key={index} fontSize='md'>
                <ListIcon as={CheckIcon} color="blue.500" />
                {item}
              </ListItem>
            ))}
          </List>}
        </div>
      </div>
    </div>
  );
};

export default ImageText;