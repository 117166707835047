const unitsTextContent = {
    itemsList: [
        'Trailers para contenedores de 20" y 40" Std',
        'Trailers para contenedores High Cube - Open Top - Flat Rack',
        'Semirremolques Barandas Rebatibles de 6,5 mts. y 14,5 mts',
        'Semirremolques Playos',
        'Carretones para el transporte de bultos fuera de medida',
        'Unidades Chasis con Pala Hidráulica',
        'Sistema Satelital GPS',
        'Telefonia Nextel en todas las unidades',
        'Unidades con sistema de cambios automaticos'
    ]
  };
  
  export { unitsTextContent };