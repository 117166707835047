import React from 'react';
import { Text } from '@chakra-ui/react'
import { Heading } from '@chakra-ui/react';
import {
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons'


const TitleBodySection = ({ title, body, sectionId, align, itemsList }) => {
  let alignClass;

  switch (align) {
    case "left":
      alignClass = "align-left"
    break;

    case "right":
      alignClass = "align-right"
    break;
    
    case "center":
    default:
      alignClass = "align-center"
      break;
  }

  return (
    <div className={`title-body-section ${alignClass}`} id={sectionId}>
      {title && <Heading size='xl' className="title-body-section--heading">{title}</Heading>}
      {body && <Text fontSize='xl' color="gray.600" className="main-text">{body}</Text>}
      {itemsList && <List spacing={2} className="title-body-section--bullets-list">
            {itemsList?.map((item, index) => (
              <ListItem color="gray.600" key={index} fontSize='xl'>
                <ListIcon as={CheckIcon} color="blue.500" />
                {item}
              </ListItem>
            ))}
          </List>}
    </div>
  )
};

export default TitleBodySection;