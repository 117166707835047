import React, { useState } from 'react';
import logo from "../../gexlog-logo.svg";
import bannerImage from "../../banner-image-2.jpg";
import imageSrc from '../../web-image.jpg';
import missionImage from '../../cargo-handshake.jpg'
import portImage from '../../port.jpg'
import trucksImage from '../../trucks.jpg'
import Header from '../../components/Header/header';
import Banner from '../../components/Banner/banner';
import Footer from '../../components/Footer/footer';
import CardSection from '../../components/Cards/cards';
import ImageText from '../../components/ImageText/image-text';
import TextSection from '../../components/TextSection/text-section';
import TitleBodySection from '../../components/TitleBodySection/title-body-section';
import ScrollToSection from '../../components/ScrollToSection/scroll-to-section';


import { bannerContent } from '../content/main/banner';
import { cardsContent } from '../content/main/cards';
import { imageTextContent } from '../content/main/imagetText';
import { contactText } from '../content/main/contact';
import { aboutUsText } from '../content/main/aboutUs';
import { missionText } from '../content/main/mission';
import { portText } from '../content/main/port';
import { transportTextContent } from '../content/main/transport';
import { unitsTextContent } from '../content/main/units';
import { transportServicesTextContent } from '../content/main/transportServices';


import {
  ChakraProvider,
} from "@chakra-ui/react";

const Main = () => {
  return (
    <ChakraProvider>
      <div className="main-page main-page__container">
        <Header img={logo} className="main-page__header" />
        <ScrollToSection />
        <div className="main-page__content">
          <Banner className="main-page__banner" title={bannerContent.title} img={bannerImage} description={bannerContent.description} />
          <TitleBodySection sectionId="about-us" title="Quienes somos" body={aboutUsText.text} />
          <ImageText sectionId="mission" title="Misión" align="left" img={missionImage} {...missionText} inverted />
          <CardSection sectionId="services" className="main-page__cards" title="Servicios" cardContent={cardsContent} />
          <ImageText sectionId="web-platform" title="Plataforma web de gestión" text={imageTextContent.text} itemsList={imageTextContent.itemsList} img={imageSrc} />
          <ImageText sectionId="port" title="Gestión portuaria" align="left" img={portImage} {...portText} inverted />
          <TitleBodySection sectionId="transport" title="Transporte" itemsList={transportTextContent.itemsList} />
          <ImageText sectionId="units" title="Nuestras unidades" itemsList={unitsTextContent.itemsList} />
          <ImageText sectionId="transport-services" title="Servicios de transporte" img={trucksImage} itemsList={transportServicesTextContent.itemsList} />
          <TextSection sectionId="contact" title="Contacto" {...contactText} />
        </div>
        <Footer className="main-page__footer" />
      </div>
    </ChakraProvider>
  );
};

export default Main;